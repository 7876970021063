import { sectionDataAdapter } from "./sectionDataAdapter";
import { buttonsAdapter } from "./buttonsAdapter";

export function sectionFormCertificatePaymentAdapter(data: any) {
  return {
    reject: {
      title: data.rejectTitle || '',
      description: data.rejectDescription || '',
    },
    success: {
      sendToEmailDescription: data.successSendToEmailDescription || '',
      sendToFnsDescription: data.successSendToFnsDescription || '',
      personalButtonLink: data.successPersonalButtonLink || '',
      personalButtonText: data.successPersonalButtonText || '',
      mainButtonLink: data.successMainButtonLink || '',
      mainButtonText: data.successMainButtonText || '',
    },
    actionButtons: buttonsAdapter(data.actionButtons),
    sectionData: sectionDataAdapter(data),
    title: data.title,
    subtitle: data.subtitle,
  }
}
